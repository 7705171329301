import {
  AptosClient,
  BCS,
  HexString,
  TransactionBuilder,
  TransactionBuilderEd25519,
  TxnBuilderTypes,
  Types
} from 'aptos';
import {
  WalletDisconnectionError,
  WalletNotConnectedError,
  WalletNotReadyError,
  WalletSignMessageError,
  WalletSignTransactionError
} from '../WalletProviders';
import {
  AccountKeys,
  BaseWalletAdapter,
  NetworkInfo,
  scopePollingDetectionStrategy,
  SignMessagePayload,
  SignMessageResponse,
  WalletAdapterNetwork,
  WalletName,
  WalletReadyState
} from './BaseAdapter';
import { sha3_256 as sha3Hash } from '@noble/hashes/sha3';

// const SNAP_ID = 'local:http://localhost:8081';
const SNAP_ID = 'npm:@rise-wallet/aptos-snap';

export const SnapWalletName = 'Snap Wallet' as WalletName<'Snap Wallet'>;

export class SnapWalletAdapter extends BaseWalletAdapter {
  name = SnapWalletName;

  url = 'https://risewallet.io';

  icon = 'https://static.risewallet.io/logo.png';

  protected _network: WalletAdapterNetwork = WalletAdapterNetwork.Devnet;

  protected _chainId: string = '1';

  protected _api: string = 'https://fullnode.devnet.aptoslabs.com';

  protected _readyState: WalletReadyState =
    typeof window === 'undefined' || typeof document === 'undefined'
      ? WalletReadyState.Unsupported
      : WalletReadyState.NotDetected;

  protected _connecting: boolean;

  protected _wallet: any | null;

  protected _client: AptosClient;

  constructor() {
    super();

    this._connecting = false;
    this._wallet = null;

    if (typeof window !== 'undefined' && this._readyState !== WalletReadyState.Unsupported) {
      scopePollingDetectionStrategy(() => {
        this._readyState = WalletReadyState.Installed;
        this.emit('readyStateChange', this._readyState);
        return true;
      });
    }
  }

  get publicAccount(): AccountKeys {
    return {
      publicKey: this._wallet?.publicKey || null,
      address: this._wallet?.address || null,
      authKey: this._wallet?.authKey || null
    };
  }

  get network(): NetworkInfo {
    return {
      name: this._network,
      api: this._api,
      chainId: this._chainId
    };
  }

  get connecting(): boolean {
    return this._connecting;
  }

  get connected(): boolean {
    return !!this._wallet?.isConnected;
  }

  get readyState(): WalletReadyState {
    return this._readyState;
  }

  async connect(): Promise<void> {
    try {
      if (this.connected || this.connecting) return;
      if (
        !(
          this._readyState === WalletReadyState.Loadable ||
          this._readyState === WalletReadyState.Installed
        )
      )
        throw new WalletNotReadyError();

      this._connecting = true;

      await window.ethereum.request({
        method: 'wallet_requestSnaps',
        params: { [SNAP_ID]: {} } as any
      });

      const publicKey: string = await window.ethereum.request({
        method: 'wallet_invokeSnap',
        params: {
          snapId: SNAP_ID,
          request: {
            method: 'getPublicKey',
            params: { derivationPath: "m/44'/637'/0'/0'/0'", confirm: true }
          }
        } as any
      });

      if (!publicKey) {
        throw new WalletDisconnectionError();
      }

      const address = this.getAddressFromPublicKey(HexString.ensure(publicKey).toUint8Array());

      this._wallet = {
        publicKey,
        address: HexString.fromUint8Array(address).toString(),
        authKey: HexString.fromUint8Array(address).toString(),
        isConnected: true
      };

      this._client = new AptosClient(this._api);

      this.emit('connect', this._wallet.publicKey);
    } catch (error: any) {
      this.emit('error', error);
      throw error;
    } finally {
      this._connecting = false;
    }
  }

  async disconnect(): Promise<void> {
    const wallet = this._wallet;
    if (wallet) {
      this._wallet = null;
    }

    this.emit('disconnect');
  }

  async signTransaction(transaction: Types.TransactionPayload): Promise<Uint8Array> {
    try {
      if (!this._wallet) throw new WalletNotConnectedError();

      const finalRawTxn = await this.generateRawTransaction(transaction);

      const signingMsg = TransactionBuilder.getSigningMessage(finalRawTxn);

      const response: { signature: string; publicKey: string } = await window.ethereum.request({
        method: 'wallet_invokeSnap',
        params: {
          snapId: SNAP_ID,
          request: {
            method: 'signTransaction',
            params: {
              derivationPath: "m/44'/637'/0'/0'/0'",
              message: HexString.fromUint8Array(signingMsg).toString()
            }
          }
        } as any
      });

      const authenticator = new TxnBuilderTypes.TransactionAuthenticatorEd25519(
        new TxnBuilderTypes.Ed25519PublicKey(
          HexString.ensure(this._wallet.publicKey).toUint8Array()
        ),
        new TxnBuilderTypes.Ed25519Signature(HexString.ensure(response.signature).toUint8Array())
      );
      const signedTransaction = new TxnBuilderTypes.SignedTransaction(finalRawTxn, authenticator);
      return BCS.bcsToBytes(signedTransaction);
    } catch (error: any) {
      const errMsg = error.message;
      this.emit('error', new WalletSignTransactionError(errMsg));
      throw error;
    }
  }

  async signAndSubmitTransaction(
    transaction: Types.TransactionPayload
  ): Promise<{ hash: Types.HexEncodedBytes }> {
    try {
      if (!this._wallet) throw new WalletNotConnectedError();

      const finalRawTxn = await this.generateRawTransaction(transaction);

      const signingMsg = TransactionBuilder.getSigningMessage(finalRawTxn);

      const response: { signature: string; publicKey: string } = await window.ethereum.request({
        method: 'wallet_invokeSnap',
        params: {
          snapId: SNAP_ID,
          request: {
            method: 'signTransaction',
            params: {
              derivationPath: "m/44'/637'/0'/0'/0'",
              message: HexString.fromUint8Array(signingMsg).toString()
            }
          }
        } as any
      });

      const authenticator = new TxnBuilderTypes.TransactionAuthenticatorEd25519(
        new TxnBuilderTypes.Ed25519PublicKey(
          HexString.ensure(this._wallet.publicKey).toUint8Array()
        ),
        new TxnBuilderTypes.Ed25519Signature(HexString.ensure(response.signature).toUint8Array())
      );
      const signedTransaction = new TxnBuilderTypes.SignedTransaction(finalRawTxn, authenticator);
      const transactionBcs = BCS.bcsToBytes(signedTransaction);

      return await this._client.submitSignedBCSTransaction(transactionBcs);
    } catch (error: any) {
      const errMsg = error.message;
      this.emit('error', new WalletSignTransactionError(errMsg));
      throw error;
    }
  }

  async signMessage(msgPayload: SignMessagePayload): Promise<SignMessageResponse> {
    try {
      if (!this._wallet) throw new WalletNotConnectedError();

      const currentChainId = await this._client.getChainId();

      const message = this.generateEncodedMessage({
        params: msgPayload,
        currentChainId,
        origin: window.location.origin
      });

      const encodedMessage = new TextEncoder().encode(message?.fullMessage);

      const response: { signature: string; publicKey: string } = await window.ethereum.request({
        method: 'wallet_invokeSnap',
        params: {
          snapId: SNAP_ID,
          request: {
            method: 'signMessage',
            params: {
              derivationPath: "m/44'/637'/0'/0'/0'",
              message: HexString.fromUint8Array(encodedMessage).toString()
            }
          }
        } as any
      });

      return {
        ...message,
        signature: response.signature
      };
    } catch (error: any) {
      const errMsg = error.message;
      this.emit('error', new WalletSignMessageError(errMsg));
      throw error;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onAccountChange(): Promise<void> {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onNetworkChange(): Promise<void> {}

  private async simulateTransaction(transaction: TxnBuilderTypes.RawTransaction) {
    const walletPublicKey = TxnBuilderTypes.AccountAddress.fromHex(this._wallet.publicKey);

    const txnBuilder = new TransactionBuilderEd25519(() => {
      const invalidSigBytes = new Uint8Array(64);
      return new TxnBuilderTypes.Ed25519Signature(invalidSigBytes);
    }, walletPublicKey.address);

    const bcsTxnSim = txnBuilder.sign(transaction);

    const sim = await this._client.submitBCSSimulation(bcsTxnSim, {
      estimateGasUnitPrice: true,
      estimateMaxGasAmount: true
    });

    return sim[0];
  }

  private async generateRawTransaction(transaction: Types.TransactionPayload) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { sequence_number } = await this._client.getAccount(this._wallet.address);

    // @ts-ignore
    const rawTxn = await this._client.generateTransaction(this._wallet.address, transaction, {
      // gas_unit_price: String(gasUnitPriceProp || gasUnitPrice),
      // max_gas_amount: String(maxGasAmount || defaultMaxGasAmount),
      sequence_number: String(sequence_number),
      expiration_timestamp_secs: String(Math.floor(Date.now() / 1000) + 30)
    });

    const simulatedTxn = await this.simulateTransaction(rawTxn);

    return this._client.generateTransaction(
      this._wallet.address,
      // @ts-ignore
      transaction,
      {
        gas_unit_price: String(simulatedTxn.gas_unit_price),
        max_gas_amount: String(Math.floor(+simulatedTxn.gas_used * 1.5)),
        sequence_number: String(sequence_number),
        expiration_timestamp_secs: String(Math.floor(Date.now() / 1000) + 30)
      }
    );
  }

  private generateEncodedMessage({ params, origin, currentChainId }) {
    const prefix = 'APTOS';
    const { nonce, message, address, application, chainId } = params;
    let fullMessage = prefix;

    if (address) {
      fullMessage += `\naddress: ${address}`;
    }
    if (application) {
      fullMessage += `\napplication: ${origin}`;
    }
    if (chainId) {
      fullMessage += `\nchainId: ${currentChainId}`;
    }
    if (message) {
      fullMessage += `\nmessage: ${message}`;
    }
    if (nonce) {
      fullMessage += `\nnonce: ${nonce}`;
    }

    return {
      fullMessage,
      address,
      application: application ? origin : false,
      chainId: currentChainId,
      message,
      nonce,
      prefix
    };
  }

  private getAddressFromPublicKey(pubkeyBytes: Uint8Array): Uint8Array {
    const hash = sha3Hash.create();
    hash.update(pubkeyBytes);
    hash.update('\x00');

    return hash.digest();
  }
}
